import axios from "axios";
import { useEffect, useState } from "react";

function Works(){
   const [works, setWorks]= useState([])
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);
   
   useEffect(() => {
     async function fetchWorks() {
       setLoading(true);
       try {
         const { data } = await axios.get('https://api.violetdecoreg.com/api/Admin/GetAllWorks');
         setWorks(data);
         setLoading(false);
       } catch (error) {
         setError(error);
         setLoading(false);
       }
     }
     fetchWorks();
   }, []) ;


   return { works, loading, error };
}
export default Works