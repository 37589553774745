import React from "react";
import { useTranslation } from 'react-i18next';
import product1Slider from "../../assets/images/categories/Cornices.jpg";
import product2Slider from "../../assets/images/categories/Accessories.jpg";
import product3Slider from "../../assets/images/categories/Door frames.jpg";
import product4Slider from "../../assets/images/categories/Frames.jpg";

import Style from './about-us.module.css'
import { Footer } from "../footer/footer";
export const AboutUs = ()=>{
  const { t, i18n } = useTranslation();

   return (
      <div className="arabic-font"  dir={i18n.language === 'ar'?"rtl":"ltr"}>
         <div className={Style.layout}>
      <div className={Style.layer}>
        <div className="d-flex justify-content-center align-items-start h-100">
          <div className="w-100 pt-4">
            <h2 className="title text-light arabic-font">{t("title")}</h2>
          </div>
        </div>
      </div>
    </div>
        <div className="row mt-5 container m-auto">
          <div className="col-md-6">
            <h2>{t("title")}</h2>
            <p>{t("titleDesc")}</p>
            {/* <p>Founded in 2005, Perfection was Simply our goal and objective. Violet is an Egyptian compa- ny dedicated to producing decorative items. A Success story, backed up only by your trust and our constant innovation to always be ahead of time using state-of-the-art technology work- ing in perfect harmony with designers and engineers to reach production capacity of 15 Million Meters/Year. Managing such a tight operation has awarded us with ISO 9001: 2015 as well as the trust of the biggest names in real estate development and interior design agencies in Egypt. Making Violet a name present in many respectable establish- ments throughout Egypt.

In that same spirit, we have produced our various products made from polyurethane to manufacture quality exterior and interior ceiling and wall decoration with contemporary designs including but not limited to: Corniches & Plates & Medallions....etc Polyurethane is exclusively used for its lightness, resistance to heat, humidity and bacteria, Being easy to install and remove in addi- tion to being maintenance free, Polyurethane is the perfect material providing international quality standards at a reasonable price to give the grandeur of the classNameic designs as well as the simplicity of the modern designs.</p> */}
            <img src={product1Slider} className="w-25" alt="About Us" />
          </div>
          <div className="col-md-6">
            <h2>{t("Vision")}</h2>
<p>{t("VisionDesc")}</p>
            <img src={product2Slider} className="w-25" alt="Vision" />
          </div>
        </div>
        <div className="row my-5 container m-auto">
          <div className="col-md-6">
            <h2>{t("Mission")}</h2>
            <p>{t("MissionDesc")}</p>
            <img src={product3Slider} className="w-25" alt="Our Commitment" />
          </div>
          <div className="col-md-6">
            <h2>{t("Goals")}</h2>
<p>1- {t("GoalsDesc1")}
<br />
2-{t("GoalsDesc2")}
<br />
3- {t("GoalsDesc3")}
<br />
4- {t("GoalsDesc4")}
</p>
            <img src={product4Slider} className="w-25" alt="Our Commitment" />
          </div>
        </div>
        {/* <div className="row mt-5 container m-auto">
          <div className="col-md-12">
            <h2>News</h2>
            <div className="card-deck">
              <div className="card mb-4">
               <div className="overflow-hidden rounded-top">
               <img src={product4Slider} className="card-img-top w-100 rounded-top" alt="News 1" />
               </div>
                <div className="card-body ">
                  <h5 className="card-title">News Title 1</h5>
                  <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis nisi sed odio mattis laoreet.</p>
                </div>
              </div>
              <div className="card mb-4">
               <div className="overflow-hidden rounded-top">
               <img src={product5Slider} className="card-img-top w-100 rounded-top" alt="News 2" />
               </div>
                <div className="card-body">
                  <h5 className="card-title">News Title 2</h5>
                  <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis nisi sed odio mattis laoreet.</p>
                </div>
              </div>
              <div className="card mb-4 ">
               <div className="overflow-hidden rounded-top">
               <img src={product6Slider} className="card-img-top w-100 rounded-top" alt="News 3" />

               </div>
                <div className="card-body">
                  <h5 className="card-title">News Title 3</h5>
                  <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis nisi sed odio mattis laoreet.</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
              {/* footer */}
      <Footer></Footer>
      </div>
    );
}