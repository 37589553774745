import Style from "./all-categories.module.css";
import Categories from "../../../shared/categories";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { Preloader } from "../../../preloader/preloader";

export const AllCategories = () => {
  const {categories, error, loading } = Categories()
  const [newCategories, setCategories] = useState([])
  const [message, setMessage] = useState('')
const [noCategoryMessage, setNoCategoryMessage] = useState('')
const [isCategory, setIsCategory] = useState(true)
const [isDeleted,setIsDeleted] = useState(false)
const [isSubmitting,setSubmitting] = useState(false)
const [loadId,setLoadId] = useState("")

  useEffect(()=>{
    console.log(categories);
    setCategories(categories)
  },[categories])
  if (loading) {
    return <Preloader/>;
  }
  
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  async function deleteCategory (id){
    try {
      setLoadId(id)
      setSubmitting(true)
      const len = newCategories?.length
      const {data} =await axios.delete(`https://api.violetdecoreg.com/api/Admin/DeleteCategory/${id}`)
      console.log('data', data);
      if (data.length!= len) {
        setCategories(data)
        setIsDeleted(true)
        setMessage('category deleted successfully!')
      }
      if (categories.length == 1) {
        setIsCategory(false);
        setNoCategoryMessage('No categories to view! ');
    }
    } catch (error) {
      console.log(error);
    }finally{
      setSubmitting(false)
    }
    }
  return (
    <>
      <section className="p-5 ">
        <div className="text-center pb-3">
          <h2 className="text-cap">All Categories</h2>
        </div>
        {isDeleted && (
         <div className="alert alert-primary" role="alert">
      {message}
       </div>
        )}

      {isCategory == false && (
         <div className="alert alert-primary" role="alert">
      {noCategoryMessage}
       </div>
        )}

        <table className={`${Style.border} table table-striped table-hover border`}>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">image</th>
              <th scope="col">name</th>
              <th scope="col">arabic name</th>
              <th scope="col">products</th>
              <th scope="col">view</th>
              <th scope="col">delete</th>
            </tr>
          </thead>
          <tbody>
            {newCategories.map((category, index)=>(
            <tr key={category.id}>
            <th scope="row">{ index + 1 }</th>
            <td><img className="w-25" src={category.attachmenturl} alt="" /></td>
            <td>{category.name}</td>
            <td>{category.name_ar}</td>

            <td>
            <Link to={`/dashboard/all-products/${category.id}`} className="btn btn-outline-primary me-2">view products</Link>
            </td>
            <td>
            <Link to={`/dashboard/category/${category.id}`} className="btn btn-outline-primary me-2">view Details</Link>
            </td>
            <td>
              <button id={category.id} onClick={()=>deleteCategory(category.id)} className="btn btn-outline-danger" disabled={category.id==loadId&&isSubmitting}>
            {category.id==loadId&&isSubmitting ? 'Deleting...' : 'delete'}</button>
            </td>
          </tr>
            ))}
          </tbody>
        </table>
      </section>
    </>
  );
};
