import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Preloader } from "../../../../preloader/preloader";

export const AllWorkImages = () => {
  const navigate = useNavigate()
   const { workId } = useParams();
const [worksImages, setWorks]= useState([])
const [loading, setLoading] = useState(true);
const [message, setMessage] = useState(null)
//const [noWorkMessage, setNoWorkMessage] = useState(null)
const [isWork, setIsWork] = useState(true)
const [isDeleted,setIsDeleted] = useState(false)
const [isSubmitting,setSubmitting] = useState(false)

useEffect(() => {
  async function fetchWorks() {
    setLoading(true);
    try {
      const { data } = await axios.get(`https://api.violetdecoreg.com/api/Admin/GetAllWorksPhotos/${workId}`);
      setWorks(data);
      console.log(worksImages.length);
      setIsWork(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }finally{
      setLoading(false)

    }
  }

  fetchWorks();
}, []) ;

async function handleDelete(id){
  try {
    setSubmitting(true)
    const len = worksImages?.length
    const { data } = await axios.delete(`https://api.violetdecoreg.com/api/Admin/DeleteWorkphoto/${id}`)
console.log(data);
// setWorks(data)
        if (data?.length != len) {
      setWorks(data)
      console.log(worksImages);
      setIsDeleted(true)
      setMessage('photo deleted successfully! ')
    }
  //     if (worksImages.length == 0) {
      
  //      setNoWorkMessage('No photos to view! ');
  //  }
  } catch (error) {
    console.log(error);
  }finally{
    setSubmitting(false)
  }
}

function handleAddImage(){
  navigate(`/dashboard/add-work-images/${workId}`)
  console.log('ddd');
}
if (loading) {
 return <Preloader/>
} else {
  return <>
  <section className="p-5 ">
    <div className="text-center pb-3 d-flex justify-content-between">
      <h2>All Work photos </h2>
      <Link className="btn btn-primary" onClick={handleAddImage}>add more</Link>
    </div>
    <table className='border table table-striped table-hover border'>
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">image</th>
          <th scope="col">delete</th>
        </tr>
      </thead>
      <tbody >
        {worksImages.map((item,index)=>(
          <tr key={item.id}>
        <th scope="row">{index+1}</th>
        <td><img className="w-25" src={item.attachmenturl} alt="" /></td>
        <td>
          {console.log(item.id)}
          <button onClick={()=>handleDelete(item.id)} className="btn btn-outline-danger"disabled={isSubmitting}>
        {isSubmitting ? 'Deleting...' : 'delete'}</button>
        </td>
      </tr>
        ))}

      </tbody>
    </table>
        {worksImages.length == 0 &&(
          <div className="alert alert-danger ">No photos to view!</div>
        )}
            {message &&(
          <div className="alert alert-danger">{message}</div>
        )}
  </section>
</>
}
 

};
