import Style from "./contact-us.module.css";
import contact from "../../assets/images/contact/Frame 1 (3).png";
import footerHeader from "../../assets/images/Violet-logo-05-edited.png";

import { ContactForm } from "../send-email/send-email";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// contact us
export const ContactUs = () => {
  const {t,i18n} = useTranslation()
  return (
    <section className="me-auto" dir={i18n.language === 'ar'?"rtl":"ltr"}>
      <div className={Style.layout}>
        <div className={Style.layer}>
          <div className="d-flex justify-content-center align-items-start h-100">
            <div className="w-100 pt-4">
              <h2 className="title text-cap text-light arabic-font">{t("contact us")}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5 me-md-0  justify-content-center">
        <div className="col-md-4 my-4">
          <img className="w-100" src={contact} alt="contact" />
        </div>
        <div className="col-md-5 rounded py-5 p-5">
          <ContactForm></ContactForm>
        </div>
      </div>
      <footer className="footer-v1  row me-auto bg-purple text-light mx-0">
        <div className="col-md-3 pt-5 me-auto">
          <div className="mb-4 ps-md-3">
          <ul className="social social-footer d-flex">
            <Link to="https://www.facebook.com/violetegy?mibextid=LQQJ4d" target="_blank" className="me-2 borderHover border">
              <i className="fa-brands fa-facebook m-2 text-white icon"></i>
            </Link>
         {/* whats apppp */}
         <Link className="d-none d-lg-block me-2 borderHover border" to="https://api.whatsapp.com/message/CAURGTVQ3O5QH1?autoload=1&app_absent=0" target="_blank">
                      <i className="fa-brands fa-whatsapp m-2 text-white icon"></i>
    </Link>

    <Link className="d-block d-lg-none me-2 borderHover border"  to="https://api.whatsapp.com/send?phone=+201018844570" target="_blank">
    <i className="fa-brands fa-whatsapp m-2 text-white icon"></i>
    </Link>
          {/*whats app end */}
            <Link to="https://www.youtube.com/channel/UCNsKjTUsM_XK3KJz2l4LUvQ"  target="_blank" className="border me-2 borderHover border">
              <i className="fa-brands fa-youtube m-2 text-white icon"></i>
              {/* <i class="fa-brands fa-youtube"></i> */}
            </Link>
            <Link to="tel:+201275666675" className="border me-2 borderHover border">
              <i className="fa-solid fa-phone text-white m-2 icon"></i>
            </Link>
          </ul>
          </div>
          <Link
            to="/visualize"
            className="text-cap text-decoration-none link text-white ps-4"
          >
            Visualize Your Products
          </Link>
          <a href="index.html" className="">
            <div className="text-center">

            <img src={footerHeader} className="w-100 mt-4" alt="footerHeader" />
            </div>
          </a>
        </div>
        {/* End Left Footer */}
        <div className="col-md-9  me-auto">
          <div className="row align-items-center text-end p-4 me-auto">
            <div>
              <div className="row">
                <div className="col-md-6 contactfooter">
                  <h5 className="fw-bold text-gold">فرع الشيخ زايد</h5>
                  <ul className="list-unstyled">
                    <li>
                      الإسكان الحدائقى امام عمارات الجومان بعد سعودى ماركت ب100
                      متر{" "}
                    </li>
                    <li>
                      <strong>للتواصل:</strong> 01000435080
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <h5 className="fw-bold text-gold">فرع المهندسين</h5>
                  <ul className="list-unstyled">
                    <li>
                      <div>154 </div>
                      <label>QNB</label>
                      شارع السودان المهندسين بجوار بنك
                    </li>
                    <li>
                      <strong>للتواصل:</strong> 01018844570
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row pt-5">
                <div className="col-md-6  contactfooter">
                  <h5 className="fw-bold text-gold">فرع أكتوبر 1 الحصرى </h5>
                  <ul className="list-unstyled">
                    <li>
                      المحور المركزي الحصري أبراج العالميه بعد مسجد الحصري
                      <div>برج رقم 6 بجوار نور للإطارات </div>
                    </li>
                    <li>
                      <strong>للتواصل:</strong> 01032214040
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <h5 className="fw-bold text-gold">
                    فرع أكتوبر 2 ميدان النجده
                  </h5>
                  <ul className="list-unstyled">
                    <li>
                      <div>239 </div>
                      المحور المركزى الحى الثالث ميدان النجده امام الاسراء بلازا
                    </li>
                    <li>
                      <strong>للتواصل:</strong> 01065656067
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-center">
            <Link className="text-decoration-none pe-4 text-cap link text-light" to="/">
            {t("home")}
            </Link>
            <Link className="text-decoration-none pe-4 text-cap link text-light" to="/our-works-videos">
            {t("ourWorks")}
            </Link>
            <Link className="text-decoration-none pe-4 text-cap link text-light" to="/products">
            {t("our products")}
            </Link>
            <Link className="text-decoration-none pe-4 text-cap link text-light" to="/our-works-images">
            {t("gallery")}
            </Link>
            <Link className="text-decoration-none pe-4 text-cap link text-light" to="/about-us">
            {t("about us")}
            </Link>
            <Link to="/privacy&policy" className="text-cap text-decoration-none link text-light pe-4">
            {t("Privacy & Policy")}
            </Link>
            <Link to="/visualize" className="text-cap text-decoration-none link text-light pe-4">
            {t("visualize")}
            </Link>
          </div>
        </div>
      </footer>
      <div className="copyright ">
        <>Copyright © 2024 Designed by 
       <Link className="text-decoration-none text-white" to="https://nasatechnology.net"> NASA technology. </Link>
         All rights reserved.</>
      </div>
    </section>
  );
};
