export const PrivacyAndPolicy = ()=>{
   return <>
    <div className="container my-5">
      <h2 className="text-center mt-5 mb-4">Privacy</h2>
      <p className="text-center">
      At Violet decor, we are committed to protecting the privacy of our clients.
      </p>
      <h2 className="text-center mb-4">Policy</h2>
      <p className="text-center">
      At Violet Decor, we are interested in offering our customers excellent decorating services, and we always strive to ensure their full satisfaction. Our installation policies reflect our commitment to providing high quality services and a safe and comfortable environment for every customer with us. Here are some of our important policies.
      </p>
    </div>
   </>
}