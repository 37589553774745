import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import Works from "../shared/all-works";
import { Preloader } from "../preloader/preloader";
import { Footer } from "../footer/footer";

export const OurWorksImages = () => {
  const { id } = useParams()
  const [worksImages, setWorks]= useState([])
 // const [loadingProducts, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {works,loading} = Works()

  async function fetchWorks() {
    console.log(loading);
   // setLoading(true);
    try {
      if (!id && works) {
        setWorks(works);
        console.log(works);
    } 
  }catch (error) {
      setError(error);
    }finally{
     // setLoading(false);
      // console.log(loading);
    }
  }
  useEffect(()=>{
    fetchWorks()
  },[works])
  
  useEffect(() => {
    if (id) {
     async function getWorkPhotos(){
        const { data } = await axios.get(`https://api.violetdecoreg.com/api/Admin/GetAllWorksPhotos/${id}`);
        setWorks(data);
        console.log(data);
      //  setLoading(false);
      }
      getWorkPhotos();
    }
  }, [id]) 

  if (loading === true) {
   // console.log('zczczc');
    return <Preloader/>
  }
  return (
    <>
      <div className="row container m-auto">
      <div className="w-100 p-4">
        <h2 className="title text-cap">our Works</h2>
      </div>
        {worksImages.map((image) => (
          <div key={image.id} className="col-md-4  card border-0 mb-4">
            <div className="overflow-hidden">
              <img className="w-100" src={image.attachmenturl} alt="violet-work" />
            </div>
          </div>
        ))}
      </div>
      <Footer/>  
    </>
  );
};
