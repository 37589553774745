import {Outlet, useLocation}  from "react-router-dom"
import { Navbar } from "../nav-bar/nav-bar"

export const Layout = ()=>{
   const location = useLocation()
   const isLogin = location.pathname === '/login'
   return <>
   {!isLogin && <Navbar/>}

<Outlet></Outlet>  
</>
}