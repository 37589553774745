import React from "react";
import { Link, Outlet } from "react-router-dom";
import sidebarLogo from "../../../assets/images/Violet-logo-05-edited.png";
import Style from './dashbord.module.css'
export const Dashboard = () => {
  return (
    <div className="container-fluid bg-light p-0">
      <div className="row container-fluid m-auto p-0">
        <div className={`${Style.sidebar} col-md-2 border-end vh-100 p-0 text-light`}>
          <div className="mx-4">
            <div className="mt-4 mb-4 ">
              <Link to='/'>
              <img src={sidebarLogo} className="w-100" />
              </Link>
      
            </div>
            <div className=" ">
              <p>Main</p>
              <div className="mb-3">
                <h5
                  className=" w-100 bg-transparent border-0 pb-0 text-light"
                  data-bs-target="#collapseExample"
                  data-bs-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  product
                </h5>
                <div className="collapse " id="collapseExample">
                  <div className="card card-body  border-0 bg-transparent p-0 ps-4">
                    <h6><Link className={`text-decoration-none ${Style.textHover}`} to='create-product'>create product</Link></h6>
                    <h6><Link className={`text-decoration-none ${Style.textHover}`} to='all-products'>all products</Link></h6>
                    {/* <h6><Link className={`text-decoration-none ${Style.textHover}`} to='product-category'>products by category</Link></h6> */}
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <h5
                  className=" w-100 bg-transparent border-0 pb-0 text-light"
                  data-bs-target="#collapseExample2"
                  data-bs-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  category
                </h5>
                <div className="collapse " id="collapseExample2">
                  <div className="card card-body  border-0 bg-transparent p-0 ps-4">
                    <h6><Link className={`text-decoration-none ${Style.textHover}`} to='create-category'>create category</Link></h6>
                    <h6><Link className={`text-decoration-none ${Style.textHover}`} to='all-categories'>all categories</Link></h6>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <h5
                  className=" w-100 bg-transparent border-0 pb-0 text-light"
                  data-bs-target="#collapseExample3"
                  data-bs-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  our works
                </h5>
                <div className="collapse" id="collapseExample3">
                  <div className="card card-body border-0 bg-transparent p-0 ps-4">
                    <h6><Link className={`text-decoration-none ${Style.textHover}`} to='create-video'>upload video</Link></h6>
                    <h6><Link className={`text-decoration-none ${Style.textHover}`} to='all-videos'>all videos</Link></h6>
                    <h6><Link className={`text-decoration-none ${Style.textHover}`} to='add-work'>add work</Link></h6>
                    <h6><Link className={`text-decoration-none ${Style.textHover}`} to='all-works'>all works</Link></h6>
                  </div>
                </div>
              </div>
              <div className="mt-5">
              <Link
                to="/"
                className="ot-btn btn-dark-color text-cap text-decoration-none"
              >
                go to website <i className="fa-solid fa-angles-right"></i>
              </Link>              
              </div>
            </div>
          </div>

        </div>
        <div className="col-md-10">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
