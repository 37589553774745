import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import Style from './homeSlider.module.css'
import { Link } from "react-router-dom";
import Categories from "../shared/categories";
import { useTranslation } from 'react-i18next'

export const HomeSlider = ({ setCategoriesSlider }) => {
  const { t, i18n } = useTranslation();
  const { categories } = Categories()
  useEffect(() => { 
    if (categories) {
      setCategoriesSlider(true) 
    } 
  }, [categories]);

  var settings = {
    
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className={`bg-test mb-5 py-5`}>
      <div className="title-block py-5">
      {i18n.language == 'ar'&&<h2 className="title text-cap arabic-font">اقسامنا</h2>}
      {i18n.language == 'en'&&<h2 className="title text-cap arabic-font">our categories</h2>}

        {/* <h2 className="title text-cap">our categories</h2> */}
        <div className="divider divider-1">
          <svg className="svg-triangle-icon-container">
            <polygon className="svg-triangle-icon" points="6 11,12 0,0 0"></polygon>
          </svg>
        </div>
      </div>
      <div className="container w-75 m-s-5">
        <Slider {...settings}>
          {categories.map((category)=> (
            <div key={category.id} className="p-3 text-center col-md-6 ">
              <div className="bg-category-bg">
                <Link to={`/products/${category.id}`} className="text-decoration-none text-dark">
                  <img src={category.attachmenturl} className="w-100" alt="" />
                  <div className="title text-cap p-3 arabic-font">
                    <h3 className="h5 ">{i18n.language === 'en' ?category.name:category.name_ar}</h3>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="row m-auto mt-5">
        <div className="text-center px-0">
          <div className={Style.overlay}>
            <div className={`justify-content-center align-items-center d-flex ${Style.layer}`}>
              <Link to="/products" className="ot-btn btn-dark-color text-cap text-decoration-none arabic-font">
              {t("our products")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
