import { Link } from 'react-router-dom'
import violetLogo from  '../../assets/images/Violet-logo-edited-04.png'
import { useRef, useState } from 'react'
import arabicLangue from '../../assets/images/flags/egypt.ico'
import englishLangue from '../../assets/images/flags/united-states.png'
import Categories from '../shared/categories'
import { useTranslation } from 'react-i18next'

export const Navbar = ()=>{
  const {t,i18n } = useTranslation()
  const {categories} = Categories()

  const [direct, setDirect] = useState(true);

  const toggleLanguage = (l) => {
    i18n.changeLanguage(l);
setDirect(!direct)
  };
  const [isHovered, setIsHovered] = useState(false);
  const [isAbout, setIsAbout] = useState(false);


  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);

  };

  const handleMouseEnterAbout = () => {
    setIsAbout(true);
    // console.log('handleMouseEnter');
  };

  const handleMouseLeaveAbout = () => {
    setIsAbout(false);
    // console.log('handleMouseLeave');
  };
   return<>
   <nav className="navbar navbar-expand-lg navbar-light bg-light pb-4">
  <div className="w-100 justify-content-center">
    <div className={`d-flex ${i18n.language === 'ar'?'justify-content-end ':'justify-content-start ms-2'}  w-100`}>
    <button className="navbar-toggler me-2 mt-1" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    </div>
 
    <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
      <div className="text-center pt-3">
            <Link><img width={280} src={violetLogo} alt="violet logo"/></Link>
      <ul className="navbar-nav me-auto mb-2 mb-lg-0 " dir={direct?"rtl":"ltr"}>
        <li className="nav-item me-2">
        <Link className="nav-link active link text-cap arabic-font" aria-current="page" to='/'>{t("home")}</Link>
        </li>

        <li onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="nav-item dropdown me-2 arabic-font">
       
          {/* data-bs-toggle="dropdown" aria-expanded="false" */}
          <Link className="nav-link dropdown-toggle link text-cap" to='/products' id="navbarDropdown" role="button" >
          {t("our categories")}
          </Link>
          {isHovered === true && (
               <ul className={`dropdown-menu d-block  ${i18n.language === 'ar'?'text-end ':'text-start'} `} aria-labelledby="navbarDropdown">
              {categories.map((category)=>(
                <li key={category.id}><Link className="dropdown-item link text-lowercase " to={`/products/${category.id}`}>{i18n.language==='en'? category.name: category.name_ar}</Link></li>
              ))}
             </ul>
      )}
        </li>
           {/* Element that shows additional content when hovered */}
           <li className="nav-item me-2 arabic-font">
          <Link className="nav-link link text-cap" to='/products'>{t("our products")}</Link>
        </li>
        <li className="nav-item me-2 arabic-font" onMouseEnter={handleMouseEnterAbout}
        onMouseLeave={handleMouseLeaveAbout}>
          <Link className="nav-link link text-cap" to='/about-us'>{t("title")}</Link>
          {isAbout === true && (
               <ul className={`dropdown-menu ${i18n.language === 'ar'?'text-end ':'text-start'} d-block`}  aria-labelledby="navbarDropdown" >
   <li><Link className="dropdown-item link text-lowercase arabic-font" to='/about-us'>{t("title")}</Link></li>
               <li><Link className="dropdown-item link text-lowercase arabic-font" to='/our-works-videos'>{t("our videos")}</Link></li>
               <li><Link className="dropdown-item link text-lowercase arabic-font" to='/our-works-images'>{t("our images")}</Link></li>
             </ul>
      )}
        </li>
        <li className="nav-item me-2 arabic-font">
        <Link className="nav-link link text-cap" to='/contact-us'>{t("contact us")}</Link>
        </li>
        {i18n.language == 'en'&&<li className=" nav-item">
  <button onClick={()=>{toggleLanguage('ar')}} className="nav-link link text-cap arabic-font">
<img src={arabicLangue} className='i8-flag me-2' alt="Arabic" />
AR
</button>
    </li>}
   
    {i18n.language == 'ar'&&<li className="nav-item">
  <button onClick={()=>{toggleLanguage('en')}} className="nav-link link text-cap arabic-font">
<img src={englishLangue} className='i8-flag ms-2' alt="English" />
En</button>

    </li>}

      </ul>
     
      </div>
   
    </div>
  </div>
</nav>
   </>
}