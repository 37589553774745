import axios from 'axios'
import Style from './all-products.module.css'
import { useNavigate, useParams } from 'react-router-dom'
import { Preloader } from '../../../preloader/preloader'
import { useEffect, useState } from 'react'

export const AllProducts = ()=>{
  const{ id } = useParams()
  const [categoryName,setCategoryName] = useState('')
const navigate = useNavigate();
const [loading, setLoading] = useState(true);
const [newProducts,setNewProducts] = useState([])
const [isProduct, setIsProduct] = useState(true)
const [message, setMessage] = useState(true)
const [noProductMessage, setNoProductMessage] = useState('')
const [isDeleted,setIsDeleted] = useState(false)
const [isSubmitting,setSubmitting] = useState(false)
const [loadId,setLoadId] = useState("")
async function getProductsByCategory(){
  try {
    const { data } = await axios.get(`https://api.violetdecoreg.com/api/Admin/GetCategoryByid/${id}`)
    console.log('getProductsByCategory',data);
    if (data.products.length == 0) {
      setIsProduct(false);
      setNoProductMessage('No products to view!');
  }
    setCategoryName(data.categoryName)
    setNewProducts(data.products)
    setLoading(false);
  } catch (error) {
    console.log(error);
  }
  }
useEffect(()=>{
  if(id){
    getProductsByCategory()
}else{
  
  fetchProducts();
}
},[id])

  async function fetchProducts() {
    try {
      const { data } = await axios.get('https://api.violetdecoreg.com/api/Admin/GetAllProducts');
      setLoading(false);
      console.log(data);
      setNewProducts(data)
      if (data.length == 0) {
        setIsProduct(false);
        setNoProductMessage('No products to view!');
    }
    } catch (error) {
   console.log(error);
      setLoading(false);
    }
  }

async function deleteProduct (productId){
  try {
    setLoadId(productId)
    setSubmitting(true)
  //const len = newProducts?.length
    const {data} =await axios.delete(`https://api.violetdecoreg.com/api/Admin/DeleteProduct/${productId}`)
  console.log('deleteProduct',data);
  if (data&&id) {
    getProductsByCategory()
  }
  if (data) {
    fetchProducts();
  }
  // if (data?.length!=len) {
  //   setNewProducts(data);
  //   if (newProducts.length == 1) {
  //     setIsProduct(false);
  //     setNoProductMessage('No products to view! ');
  // }
    setIsDeleted(true)
     setMessage('product deleted successfully!')
  // }
} catch (error) {
  console.log(error);
}  finally{
  setSubmitting(false)
}
}

function getProductById(id) {
  navigate(`/dashboard/product-details/${id}`);
}
if (loading) {
 return <Preloader/>
}else{
   return<>
   <section className="p-5">
      <div className='text-center pb-3'>
      <h2>All {categoryName} Products</h2>
      </div>
      
      {isDeleted && (
         <div className="alert alert-primary" role="alert">
      {message}
       </div>
        )}

      {isProduct == false && (
         <div className="alert alert-primary" role="alert">
      {noProductMessage}
       </div>
        )}
   <table className= {`${Style.border} table table-striped table-hover border`}>
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">image</th>
      <th scope="col">code</th>
      {/* <th scope="col">category name</th> */}
      <th scope="col">view</th>
      <th scope="col">delete</th>
    </tr>
  </thead>
  <tbody>
{newProducts?.map((product, index)=>(
    <tr key={product.id}>
    <th scope="row">{index+1}</th>
    <td  className='w-25'><img  className='w-50' src={product.attachmenturl} alt="violet-product" /></td>
    <td>{product.code}</td>
    {/* <td>{product.categoryName}</td> */}
    <td>
            <button onClick={()=>getProductById(product.productId)} className=" btn btn-outline-primary me-2">view</button>
          </td>
          <td>
            <button onClick={()=>deleteProduct(product.productId)} className="btn btn-outline-danger"  disabled={isSubmitting&&loadId==product.productId}>
            {loadId==product.productId&&isSubmitting ? 'Deleting...' : 'delete'}
            </button>
          </td>
  </tr>
            ))}


  </tbody>
</table>
   </section>
   </>
}
}