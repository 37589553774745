import axios from "axios";
import Videos from "../../../shared/all-videos";
import { useEffect, useState } from "react";
import { Preloader } from "../../../preloader/preloader";

export const AllVideos = () => {
const {videos,loading} = Videos()
const [newVideos, setNewVideos] = useState([])
const [message, setMessage] = useState('')
const [noWorkMessage, setNoWorkMessage] = useState('')
const [isWork, setIsWork] = useState(true)
const [isDeleted,setIsDeleted] = useState(false)
const [isSubmitting,setSubmitting] = useState(false)

 console.log(videos);

 useEffect(()=>{
  setNewVideos(videos)
 },[videos])

 if (loading) {
  return <Preloader/>;
}
  async function deleteVideo(id){
    try {
      setSubmitting(true)
      const len = newVideos?.length
      const { data } =await axios.delete(`https://api.violetdecoreg.com/api/Admin/DeleteVideo/${id}`)
      console.log(data);
      if (data.length != len) {
        setNewVideos(data)
        setIsDeleted(true)
        setMessage('video deleted successfully! ')
      }
      if (data=='Removed') {
       if (newVideos.length == 1) {
        setIsWork(false);
        setNoWorkMessage('No videos to view! ');
    }
  }
    } catch (error) {
      console.log(error);
    }finally{
      setSubmitting(false)
      
    }
  
  }

  return (
    <>
      <section className="p-5 ">
        <div className="text-center pb-3">
          <h2>All Videos</h2>
        </div>
        {isDeleted && (
         <div className="alert alert-primary" role="alert">
      {message}
       </div>
        )}
      {isWork == false && (
         <div className="alert alert-primary" role="alert">
      {noWorkMessage}
       </div>
        )}
        <table className='border table table-striped table-hover border'>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">video</th>
              <th scope="col">title</th>
              <th scope="col">description</th>
              <th scope="col">delete</th>
            </tr>
          </thead>
          <tbody>
            {newVideos.map((video, index)=>(
            <tr key={video.id}>
            <th scope="row"> {index+1} </th>
            <td>
  <video controls className="w-50">
    <source src={video.attachmentUrl} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</td>
            <td>{video.title}</td>
            <td>{video.description}</td>
            <td>
              <button onClick={()=>deleteVideo(video.id)} className="btn btn-outline-danger"disabled={isSubmitting}>
            {isSubmitting ? 'Deleting...' : 'delete'}</button>
            </td>
          </tr>
            ))}
          </tbody>
        </table>
      </section>
    </>
  );
};
