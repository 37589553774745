import axios from "axios"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import ZoomPan from 'react-zoom-pan';
import { Magnifier } from 'react-image-magnifiers';
import { Preloader } from "../preloader/preloader";
import { useTranslation } from "react-i18next";

export const ProductById = ()=>{
   const{t,i18n} = useTranslation()

   const { id } = useParams()
   const [product, setProduct] = useState({})
   const[relatedProducts, setRelatedProducts]= useState([])
   const [productImage, setProductImage] = useState("");
   const [loading, setLoading] = useState(true);

   async function getProduct (){
      try {
         setLoading(true)
         const {data} =await axios.get(`https://api.violetdecoreg.com/api/Admin/GetProductbyId/${id}`)
         setProduct(data)
         console.log(data);
         getRelatedProduct(data.categoryId)
         setProductImage(data.attachment);
         
      } catch (error) {
         console.log(error);
      }finally{
         setLoading(false)
      }

   }
   async function getRelatedProduct (categoryId){
      const {data} =await axios.get(`https://api.violetdecoreg.com/api/Admin/GetRelatedProduct/${categoryId}`)
      setRelatedProducts(data)
      console.log(data);
   }
   useEffect(()=>{
      getProduct()
   },[id])

    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      autoplay: true,
  
      slidesToShow: 5,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
            
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
   return <>
   {loading&&(
      <Preloader/>
   )}
   <div className="container ">
      <div className={`row ${i18n.language === 'ar'? 'flex-row':'flex-row-reverse'} m-auto text-center`}>
<div className="col-md-6 product-image"   >
   {productImage&&(
  
      <Magnifier
        imageSrc={productImage}
        imageAlt="Product"
        mouseActivation="hover"
        dragToMove={false}
        cursorStyleActive="crosshair"
      />

   )}
    </div>
      <div className="col-md-6 pt-5 mt-5">
         <div className={`d-flex ${i18n.language === 'ar'? 'flex-row-reverse':'flex-row'} justify-content-center`}>
      <h6 className="text-muted arabic-font">{t("product code")} </h6> 
      <span className="text-dark arabic-font"> {product.code}</span>
         </div>
      {i18n.language === 'ar' ?  <p className="arabic-font">{product.details_ar}</p> :  <p className="arabic-font">{product.details}</p>}
     
      <p className="arabic-font">{t("size")} : {product.size}</p>
      <p className="arabic-font">{t("long")} : {product.long}</p>
      <div className=" mt-5">
            <Link to={'/products'} className="btn my-btn btn text-cap p-2 rounded-0 arabic-font">{t("view all products")}</Link>
         </div>
      </div>
      </div>


      <div className="slider-container container m-auto my-5 px-3">
         <div className=" text-end w-100">
         <a className={`text-cap h4 arabic-font  ${i18n.language === 'ar'?'text-end ':'text-start'} `}> {t("related products")}</a>

         </div>
      <Slider {...settings}>
      {relatedProducts.map((product)=>(
        <div className="p-3 text-center col-md-6">
          <Link to={`/product/${product.id}`} className="text-decoration-none title text-cap text-dark">
          <img src={product.attachment} className="w-100" alt="" />
        <div className="bg-category-bg p-3">
         <h4 className="arabic-font">{product.code}</h4>
        </div>
          </Link>
       </div>
      ))}
</Slider>
<div className="text-center pt-5">
            <Link to={'/products'} className="ot-btn btn-dark-color text-cap text-decoration-none arabic-font">{t("view more products")}</Link>
         </div>
    </div>
   </div>
   </>
}