import { Link, useNavigate, useParams } from "react-router-dom";
import violetLogo from  '../../assets/images/Violet-logo-edited-04.png';
import violetProducts from  '../../assets/images/our-products/3 (5).gif';
import productGif from  '../../assets/images/our-products/1 (5) (1).gif';
import Categories from "../shared/categories";
import SharedProducts from "../shared/products";
import { useEffect, useState } from "react";
import axios from "axios";
import { Footer } from "../footer/footer";
import { Preloader } from "../preloader/preloader";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

export const Products = () => {
  const { t,i18n } = useTranslation();
  const [allProducts, setProducts] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const { categories } = Categories();
  const { products, error: productsError, loading: productsLoading } = SharedProducts();
  const [ categoryId , setId ] =useState(0)
  const itemsPerPage = 15; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(allProducts.length / itemsPerPage);
  const windowSize = 3; // Number of pages to display in the pagination controls

  const handleClick = (page) => {
    setCurrentPage(page);
  };

  const handlePrevious = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayProducts = allProducts.slice(startIndex, startIndex + itemsPerPage);

  // Calculate the start and end indices of the window
  const startPage = Math.max(1, currentPage - Math.floor(windowSize / 2));
  const endPage = Math.min(totalPages, startPage + windowSize - 1);
useEffect(()=>{
console.log(displayProducts);
},[displayProducts])

  async function fetchData() {
    if (!id) {
      setProducts(products);
    } else {
      try {
        console.log(id);
        const { data } = await axios.get(`https://api.violetdecoreg.com/api/Admin/GetCategoryByid/${id}`);
        setProducts(data.products);
      } catch (error) {
        console.error("Error fetching category:", error);
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, [id, products]);


  const handleRemoveParams = () => {
    navigate(`/products`);
    setId(0)
  };

  if (productsLoading) {
    return <Preloader/>
  }
  
  if (productsError) {
    return <div>Error: {productsError.message}</div>;
  }

  function handleParams(categoryId) {
    navigate(`/products/${categoryId}`);
    setId(categoryId)
  }

  var settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 10,
    autoplay: true,

    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 1 
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return <>
<Slider  className="w-50 m-auto mt-5"{...settings}>
        <div className="p-3 text-center col-md-6">
          <Link  className="text-decoration-none title text-cap text-dark">
          <img src={violetProducts} className="w-100" alt="" />
          </Link>
       </div>
       <div className="p-3 text-center col-md-6">
          <Link  className="text-decoration-none title text-cap text-dark">
          <img src={productGif} className="w-100" alt="" />

          </Link>
       </div>
   
</Slider>
      <section>
        <div className={`d-flex ${ i18n.language === 'ar' ? 'flex-row-reverse' : 'flex-row'} container-fluid p-5 pt-3 `}>
          <div className="col-md-3 text-center">
            <Link className="">
              <img src={violetLogo} alt="violet logo" className="w-75 p-2"/>
            </Link>
            <ul className="list-group">
            <li onClick={handleRemoveParams} className={`list-group-item cursor-pointer text-cap link  ${categoryId === 0 ? 'current' : ''}`}><h4 className="h6 ">{t("all products")}</h4></li>
              {categories.map((category) => (
                <li key={category.id} onClick={() => handleParams(category.id)}  className={`arabic-font list-group-item cursor-pointer text-cap link ${categoryId === category.id ? 'current' : ''}`}><h4 className="h6 ">{i18n.language === 'en' ?category.name:category.name_ar}</h4></li>
                ))}
            </ul>
          </div>
          <div className="col-md-9 me-auto mt-5">
            <div className="ps-3 pt-3"></div>
            <div className="row container gy-4 justify-content-end">

            {displayProducts?.map((product) => (
    <Link to={`/product/${product.productId}`} key={product.productId} className=" col-md-4 cursor-pointer text-decoration-none">
      <div className="product card text-center overflow-hidden border-0 bg-purple">
        <img src={product.attachmenturl} className=" card-img-top m-auto" alt="product" />
        <div className="card-hover ">
          <p className="card-text fw-bold text-gold bg-hover w-100 p-3 ">{product.code}</p>
        </div>
      </div>
    </Link>
  
))}
            </div>
          </div>
          
        </div>
        <div className="d-flex justify-content-center">
        <nav aria-label="...">
        <ul className={`pagination ${i18n.language=== 'ar'? 'flex-row-reverse':'flex-row'} cursor-pointer`}>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <span className={`page-link text-gold ${i18n.language=== 'ar'? 'rounded-0':''} `} onClick={handlePrevious}>
              {t("Previous")}
            </span>
          </li>
          {Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          ).map((page) => (
            <li
              key={page}
              className={`page-item ${currentPage === page ? 'active' : ''}`}
            >
              <span className="page-link text-gold" onClick={() => handleClick(page)}>
                {page}
              </span>
            </li>
          ))}
          <li className={`page-item  ${currentPage === totalPages ? 'disabled' : ''}`}>
            <span className={`page-link text-gold ${i18n.language=== 'ar'? 'rounded-0':''} `} onClick={handleNext}>
              {t("Next")}
            </span>
          </li>
        </ul>
      </nav>
      
        </div>
              {/* footer */}
      <Footer></Footer>
      </section>
    </>
};

