
import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cornices from "../../assets/images/categories/Cornices.jpg";
import Style from './images-slider.module.css'
import Works from '../shared/all-works'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
function ImagesSlider({ setImagesSlider }) {
  const { t,i18n } = useTranslation();

const {works,loading} = Works()
console.log("works",works);

useEffect(() => { 
  if (works) {
    setImagesSlider(true) 
  //  sendLoading() 
  } 
}, [works]);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,

    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
   <section className={` bg-test mb-5 py-5`}>
          <div className="title-block py-5">
          <h2 className="title text-cap arabic-font">{t("our images")}</h2>
          <div className="divider divider-1">
            <svg className="svg-triangle-icon-container">
              <polygon
                className="svg-triangle-icon"
                points="6 11,12 0,0 0"
              ></polygon>
            </svg>
          </div>
        </div>
    <div className="slider-container container w-75 m-auto">
      <Slider {...settings}>
      {works.map((work)=> (
            <div key={work.id} className="p-3 text-center col-md-6 ">
              <div className="bg-category-bg">
                <Link to={`/our-works-images/${work.id}`} className="text-decoration-none text-dark">
                  <img src={work.attachmenturl} className="w-100" alt="" />
                  <div className="title text-cap p-3 arabic-font">
                    <h3 className="h5">{i18n.language==='en'?work.name:work.name_ar}</h3>
                  </div>
                </Link>
              </div>
            </div>
          ))}
</Slider>
    </div>
   </section>

  );
}

export default ImagesSlider;
