import { LatestProducts } from "../latest-products/latest-products";
import whatWeDo1 from "../../assets/images/home/1060x500.jpg";
import whatWeDo2 from "../../assets/images/home/1060x500-2.jpg";
import whatsApp from '../../assets/images/social icons/whatsapp.png'

import { SimpleSlider } from "../hero-section/hero-section";
import { HomeSlider } from "../homeSliders/homeSliders";
import { OurWorks } from "../our-works-section/our-works";
import { Link } from "react-router-dom";
import { Footer } from "../footer/footer";
import { Preloader } from "../preloader/preloader";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ImagesSlider from "../images-slider/images-slider";

export const HomeComponent = () => {
  const { t,i18n } = useTranslation();

  const [categoriesSlider, setCategoriesSlider] = useState(false)
  const [products, setProducts] = useState(false)
  const [videos, setVideos] = useState(false)
  const [imagesSlider, setImagesSlider] = useState(false)

useEffect(()=>{
  if (categoriesSlider&&products&&videos&&imagesSlider) {
   // setLoading(true)
      console.log('loadingggggggggggggg',categoriesSlider, products, videos, imagesSlider);
  }
 
},[categoriesSlider, products, videos, imagesSlider])

  return <> 
  {!(categoriesSlider&&products&&videos&&imagesSlider)&&(
    <Preloader/>
  )}
      <SimpleSlider></SimpleSlider> 
    
      <HomeSlider setCategoriesSlider={setCategoriesSlider}></HomeSlider>
        <Link className="d-none d-lg-block" to="https://api.whatsapp.com/message/CAURGTVQ3O5QH1?autoload=1&app_absent=0" target="_blank">
      <img className="whatsAppIcon" src={whatsApp} alt="WhatsApp call icon" />
    </Link>

    <Link className="d-block d-lg-none"  to="https://api.whatsapp.com/send?phone=+201018844570" target="_blank">
      <img className="whatsAppIcon" src={whatsApp} alt="WhatsApp call icon" />
    </Link>
     
      <section  className="p-5 parent child-left">
        <div className="container pb-5 mb-5">
        <div className="">
        <div className="row">
            <div className="title-block mb-5 ">
              <h2 className="title text-cap arabic-font">{t("title")}</h2>
              <div className="divider divider-1">
                <svg className="svg-triangle-icon-container">
                  <polygon
                    className="svg-triangle-icon"
                    points="6 11,12 0,0 0"
                  ></polygon>
                </svg>
              </div>
            </div>
            <div className=""  dir={i18n.language === 'ar'?"rtl":"ltr"}>
              <div className="row">
              <div className="mb-3 col-md-6 col-sm-12 d-md-none">
                  <img src={whatWeDo1} className="w-100 rounded" alt="shopProduct2" />
                </div>
                <div className="col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
                  <div className="mb-4 arabic-font">
                    <h4 className={`text-uppercase mb-3  ${i18n.language === 'ar' ? 'text-end' : ''}`}>{t("title")}</h4>
                    <p className="text-muted">
                   {t("titleDesc")}
                    {/* Founded in 2005, Perfection was Simply our goal and objective. Violet is an Egyptian compa- ny dedicated to producing decorative items. A Success story, backed up only by your trust and our constant innovation to always be ahead of time using state-of-the-art technology work- ing in perfect harmony with designers and engineers to reach production capacity of 15 Million Meters/Year. Managing such a tight operation has awarded us with ISO 9001: 2015 as well as the trust of the biggest names in real estate development and interior design agencies in Egypt. Making Violet a name present in many respectable establish- ments throughout Egypt. */}
                    </p>
                    <div className={`${i18n.language === 'ar' ? 'text-end' : ''}`}>
                    <Link to="/about-us" className={`my-btn btn text-cap  ${i18n.language === 'ar' ? 'text-end' : ''}`}>
                    {t("Continue reading")}
                    </Link>
                    </div>
                  </div>
                </div> 
                <div className="col-md-6 d-none d-md-block d-lg-block ">
                  <img src={whatWeDo1} className="w-100 rounded" alt="shopProduct2" />
                </div>
              </div>
              <div className=" mb-5 row">
                <div className="col-md-6 col-sm-12 mb-sm-3" >
                  <img src={whatWeDo2} className="w-100 rounded" alt="shopProduct1" />
                </div>
                <div className="col-md-6 col-sm-12 d-flex justify-content-center align-items-center arabic-font">
                  <div >
                  <h4 className={`text-uppercase mb-3 ${i18n.language === 'ar' ? 'text-end' : ''}`}>
                   {t("Vision")}</h4>                    
                   <p className="text-muted">
                      {t("VisionDesc")}
                    {/* Home is your own personal palace, every person dreams of the perfect home, some might even strive to build it as it should. We take it as our responsibility to build the house of your dreams with your own personal touches, our designs suit each person's individual taste and we aim to be part of your life at home or even the places you go to. Our goal is to elevate the public decorum and achieve the most beautiful results at the best prices and quality. */}
                    </p>
                    <div className={`${i18n.language === 'ar' ? 'text-end' : ''}`}>
                    <Link to="/about-us" className={`my-btn btn text-cap  ${i18n.language === 'ar' ? 'text-end' : ''}`}>
                    {t("Continue reading")}
                    </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
          

       <LatestProducts setProducts={setProducts}></LatestProducts>
      
      <OurWorks setVideos={setVideos}></OurWorks>
      <ImagesSlider setImagesSlider={setImagesSlider}></ImagesSlider>
  
      <section className="">
        <div className="overlay-arc2 overlay-3">
          <div className="layer-1 text-center">
            <div className="box-content-overlay-3 arabic-font">
              <h2
                className="fw-normal text-dark wow fadeInDown"
                data-wow-delay=".15s"
              >
               {t("visualizeDesc")}
              </h2>
              <Link
                to="/visualize"
                className="ot-btn btn-dark-color text-cap text-decoration-none arabic-font"
              >
                {t("visualize")}
              </Link>
            </div>
          </div>
        </div>
      </section>
       <Footer/>  
    </>
    
 
  
};
