import { Link } from "react-router-dom";
import Videos from "../shared/all-videos";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'

export const OurWorks = ({ setVideos }) => {
  const { t, i18n } = useTranslation();

  const { videos,loading } = Videos();
  const [randomVideos, setRandomVideos] = useState([]);
  useEffect(() => { 
    if (videos) {
      setVideos(true) 
    } 
  }, [videos]);

  useEffect(() => {
    // Shuffle the videos array
    const shuffledVideos = shuffleArray(videos);
    // Select the first two elements
    const selectedVideos = shuffledVideos.slice(0, 2);
    setRandomVideos(selectedVideos);
  }, [videos]);

 // Function to shuffle the array
 const shuffleArray = (array) => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
 }
  return (
    <>
      {/* our works */}
      <section className="my-5 container">
        <div className="">
          <div className="title-block py-5">
            <h2 className="title text-cap arabic-font">{t("ourWorks")}</h2>
            <div className="divider divider-1">
              <svg className="svg-triangle-icon-container">
                <polygon
                  className="svg-triangle-icon"
                  points="6 11,12 0,0 0"
                ></polygon>
              </svg>
            </div>
          </div>
          <div className="row">
            {randomVideos.map((video, index) => (
              <div className="col-md-12 col-lg-6 card border-0" key={index}>
                <div className="overflow-hidden">
                  <video controls className="w-100">
                    <source src={video.attachmentUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="card-body text-sm-center">
                  <Link className="text-decoration-none text text-dark">
                    <h5 className="card-title arabic-font">
                     {video.title}
                    </h5>
                  </Link>

                  <p className="card-text arabic-font">
                  {video.title}
                  </p>
                </div>
              </div>
           
            ))}
            <div className="text-center pt-4">
            <Link to="/our-works-videos" className="my-btn btn text-cap arabic-font">
                    {t("ViewMore")}
                  </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
