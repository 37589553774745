import logo from "../../../assets/images/Violet-logo-edited-09.png";


export const DashboardLogo = ()=> {
   return <>
  <div className="d-flex justify-content-center align-items-center ">
          <div className="text-center">
            <img src={logo} alt="violet logo" />
            <h1 className="text-cap">welcome to your dashboard</h1>
         </div>
          </div>
   </>
}