import axios from "axios";
import { useEffect, useState } from "react";

function NewProducts(id){
   const [newProducts, setProducts]= useState([])
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);
   
   useEffect(() => {
     async function fetchProducts(id) {
       setLoading(true);
       try {
         const { data } = await axios.get(`https://api.violetdecoreg.com/api/Admin/newProducts?id=${id}`);
         setProducts(data);
         setLoading(false);
       } catch (error) {
         setError(error);
      console.log(error);
         setLoading(false);
       }
     }
     fetchProducts(id);
   }, [id]) ;


   return { newProducts, loading, error};
}
 
export default NewProducts