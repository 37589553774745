import { Link } from 'react-router-dom';
import footerHeader from '../../assets/images/Violet-logo-05-edited.png';
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const {t,i18n} = useTranslation()

  return (
    <>
      <footer className="footer-v1 d-flex "  dir={i18n.language === 'ar'?"rtl":"ltr"}>
        <div className="col-md-3" >
          <a href="index.html">
            <img src={footerHeader} className="w-100" alt="footerHeader" />
          </a>
        </div>
        {/* End Left Footer */}
        <nav className='col-md-3 col-sm-12'>
          <div className="d-flex flex-wrap justify-content-center">
            <Link className="text-decoration-none pe-4 text-cap link text-light" to="/">
            {t("home")}
            </Link>
            <Link className="text-decoration-none pe-4 text-cap link text-light" to="/our-works-videos">
            {t("ourWorks")}
            </Link>
            <Link className="text-decoration-none pe-4 text-cap link text-light" to="/products">
            {t("our products")}
            </Link>
            <Link className="text-decoration-none pe-4 text-cap link text-light" to="/our-works-images">
            {t("gallery")}
            </Link>
            <Link className="text-decoration-none pe-4 text-cap link text-light" to="/about-us">
            {t("about us")}
            </Link>
            <Link to="/privacy&policy" className="text-cap text-decoration-none link text-light pe-4">
            {t("Privacy & Policy")}
            </Link>
            <Link to="/visualize" className="text-cap text-decoration-none link text-light pe-4">
            {t("visualize")}
            </Link>
          </div>
        </nav>
        {/* End Nav Footer */}
        <div className="footer-right col-md-3 col-sm-12">
          <ul className="social social-footer d-flex">
            <Link to="https://www.facebook.com/violetegy?mibextid=LQQJ4d" target="_blank" className="me-2 borderHover border">
              <i className="fa-brands fa-facebook m-2 text-white icon"></i>
            </Link>
                      {/* whats apppp */}
                      <Link className="d-none d-lg-block me-2 borderHover border" to="https://api.whatsapp.com/message/CAURGTVQ3O5QH1?autoload=1&app_absent=0" target="_blank">
                      <i className="fa-brands fa-whatsapp m-2 text-white icon"></i>
    </Link>

    <Link className="d-block d-lg-none me-2 borderHover border"  to="https://api.whatsapp.com/send?phone=+201018844570" target="_blank">
    <i className="fa-brands fa-whatsapp m-2 text-white icon"></i>
    </Link>
          {/*whats app end */}
            {/* <Link  to="https://api.whatsapp.com/message/CAURGTVQ3O5QH1?autoload=1&app_absent=0" target="_blank" className="border me-2 borderHover border">
              <i className="fa-brands fa-whatsapp m-2 text-white icon"></i>
            </Link> */}
            <Link to="https://www.youtube.com/channel/UCNsKjTUsM_XK3KJz2l4LUvQ"  target="_blank" className="border me-2 borderHover border">
              <i className="fa-brands fa-youtube m-2 text-white icon"></i>
              {/* <i class="fa-brands fa-youtube"></i> */}
            </Link>
            <Link to="tel:+201275666675" className="border me-2 borderHover border">
              <i className="fa-solid fa-phone text-white m-2 icon"></i>
            </Link>
          </ul>
        </div>
		  
        {/* End Right Footer */}
      </footer>
      {/* End Footer */}
      <section className="copyright">      
      <div className="copyright ">
        <>Copyright © 2024 Designed by 
       <Link className='text-decoration-none text-white' to="https://nasatechnology.net"> NASA technology. </Link>
         All rights reserved.</>
      </div>
      </section>
    </>
  );
};
