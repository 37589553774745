import { useEffect, useState } from "react";
import Style from "./visualize.module.css";
import { Footer } from "../footer/footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Preloader } from "../preloader/preloader";
import { useTranslation } from "react-i18next";

export const Visualize = () => {
  const {t,i18n} = useTranslation()
  const navigate = useNavigate()
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getVisualizeCategories()
  },[]);

async function getVisualizeCategories(){
  try {
    const { data } = await axios.get('https://api.violetdecoreg.com/api/Admin/GetAllVisualizeCategories')
    console.log(data);
    setCategories(data)
    setIsLoading(false)
  } catch (error) {
    console.log(error);
  }
}

  const handleVisualize = (categoryId) => {
    // setIsVisualize(true);
    navigate(`/visualize/${categoryId}`)
  };
if(isLoading){
return <Preloader/>
}else{
  return <>
        <section>
          <div className={Style.layout}>
            <div className={Style.layer}>
              <div className="d-flex justify-content-center align-items-start h-100">
                <div className="w-100 p-4">
                  <h2 className="title text-cap text-light arabic-font">{t("categories")}</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="container row justify-content-center align-items-center my-5 m-auto gy-5 ">
            {categories.map((category)=>(
            <div key={category.id} onClick={()=>handleVisualize(category.id)} className="card col-md-3  border-0 cursor-pointer">
            <div className="overflow-hidden">
              <img src={category.attachmenturl} className="card-img-top" alt="Cornices" />
            </div>
            <div className="card-body text-center bg-category-bg">
              <div className="py-2">
                <h5 className="title arabic-font">{i18n.language === 'en'? category.name: category.name_ar}</h5>
              </div>
            </div>
          </div>
          ))}

            {/* <div onClick={handleVisualize} className="card col-md-3  border-0 cursor-pointer">
              <div className="overflow-hidden">
                <img src={Cornices} className="card-img-top" alt="Cornices" />
              </div>
              <div className="card-body text-center bg-category-bg">
                <div className="py-2">
                  <h5 className="title text-cap">Cornices</h5>
                </div>
              </div>
            </div>
            <div onClick={handleVisualize} className="card col-md-3  border-0 cursor-pointer">
              <div className="overflow-hidden">
                <img src={Plates} className="card-img-top" alt="Cornices" />
              </div>
              <div className="card-body text-center bg-category-bg">
                <div className="py-2">
                  <h5 className="title text-cap">Plates</h5>
                </div>
              </div>
            </div>
            <div onClick={handleVisualize} className="card col-md-3  border-0 cursor-pointer">
              <div className="overflow-hidden">
                <img src={DoorFrames} className="card-img-top" alt="Cornices" />
              </div>
              <div className="card-body text-center bg-category-bg">
                <div className="py-2">
                  <h5 className="title text-cap">Door Frames</h5>
                </div>
              </div>
            </div> */}
            {/* <div onClick={handleVisualize} className="card col-md-3  border-0 cursor-pointer">
              <div className="overflow-hidden">
                <img src={Columns} className="card-img-top" alt="Cornices" />
              </div>
              <div className="card-body text-center bg-category-bg">
                <div className="py-2">
                  <h5 className="title text-cap">Columns & Edges</h5>
                </div>
              </div>
            </div>
            <div onClick={handleVisualize} className="card col-md-3  border-0 cursor-pointer">
              <div className="overflow-hidden">
                <img src={Medallions} className="card-img-top" alt="Cornices" />
              </div>
              <div className="card-body text-center bg-category-bg">
                <div className="py-2">
                  <h5 className="title text-cap">Medallions</h5>
                </div>
              </div>
            </div> */}
            {/* <div onClick={handleVisualize} className="card col-md-3  border-0 cursor-pointer">
              <div className="overflow-hidden">
                <img src={WallPlaques} className="card-img-top" alt="Cornices" />
              </div>
              <div className="card-body text-center bg-category-bg">
                <div className="py-2">
                  <h5 className="title text-cap">Wall Plaques</h5>
                </div>
              </div>
            </div> */}
            {/* <div onClick={handleVisualize} className="card col-md-3  border-0 cursor-pointer">
              <div className="overflow-hidden">
                <img src={Accessories} className="card-img-top" alt="Cornices" />
              </div>
              <div className="card-body text-center bg-category-bg">
                <div className="py-2">
                  <h5 className="title text-cap">Accessories</h5>
                </div>
              </div>
            </div> */}
          </div>
                {/* footer */}
      <Footer></Footer>
        </section>
        
    </>
}
};
