import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Categories from "../../../shared/categories";
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';

export const CreateProduct = () => {
  const { id } = useParams()

  const [imageErrMsg,setImageErr] = useState('')
  const navigate = useNavigate()
  const { categories } = Categories();

const [initialValues, setInitialValues] = useState({
  // src:'',
  Files: undefined,
  code: "",
  Long: "", 
  Size: "",
  Description: "",
  Description_ar: "",
  CategoryId: '',
  //Category_arId: '',
})

const [loading, setLoading] = useState(true); // Add loading state
const [formHeader, setFormHeader] = useState(''); // Add loading state
const [imageUrl, setImageUrl] = useState('');

const handleFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = () => {
      setImageUrl(reader.result);
      console.log('image',imageUrl);
    };
    reader.readAsDataURL(file);
  }else{
    setImageUrl('');

  }
}
useEffect(()=>{
  if (id) {
    setFormHeader('Update Product')
  }else{
    setFormHeader('Create Product')
  }
},[])

useEffect(()=>{
  async function fetchProductDetails(){
  try{
    if(id){
      const {data} = await axios.get(`https://api.violetdecoreg.com/api/Admin/GetProductbyId/${id}`)
      console.log(data);
      setImageUrl(data.attachment)
      setInitialValues({
        src:data.attachment,
        code:data.code,
        Long:data.long,
        Size:data.size,
        Description:data.details,
        Description_ar:data.details_ar,
        CategoryId:data.categoryId,
        //CategoryId:data.categoryId_ar,
        })
      }
    }catch(err){
      console.error('Error fetching product:', err);
    }finally {
      setLoading(false);
    }
  }
  fetchProductDetails()
},[id])

  async function createProduct(values, { setSubmitting }) {
    try {
      console.log(values);
      const formData = new FormData();
      formData.append("Files", values.Files);
      formData.append("Code", values.code);
      formData.append("Long", values.Long);
      formData.append("Size", values.Size);
      formData.append("Details", values.Description);
      formData.append("Details_ar", values.Description_ar);
      formData.append("CategoryId", values.CategoryId);
     // formData.append("Category_arId", values.Category_arId);

if (id) {
  const { data } = await axios.put(
    `https://api.violetdecoreg.com/api/Admin/UpdateProduct/${id}`,
    formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  console.log( 'updateProduct' ,data);
  navigate(`/dashboard/product-details/${id}`)
} else if(values.Files!=undefined)  {

  const { data } = await axios.post(
    "https://api.violetdecoreg.com/api/Admin/Addproduct",
    formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  console.log( 'AddProduct' ,data);
  navigate('/dashboard/all-Products')
}
else{
    setImageErr('image is required!')
}
    } catch (error) {

      console.log(error);
    }finally{
      setSubmitting(false)
    }
  }

  const validationSchema = Yup.object().shape({
    Files: Yup.mixed(),
    code: Yup.string().required("Code is required"),
    Long: Yup.string().required("Long is required"),
    Size: Yup.string().required("Size is required"),
    Description: Yup.string(),
    Description_ar: Yup.string(),
    CategoryId: Yup.string().required("Category is required"),
    //Category_arId: Yup.string().required("Arabic Name is required"),
  });

  return (
    <section className="p-5">
      <h2 className="text-center text-cap">{formHeader}</h2>
      {!loading && (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={createProduct}
      >
        {({ errors, touched, setFieldValue, isSubmitting }) => (
          <Form>
              <div className="d-flex align-items-center  text-center mt-3">
         
            <div className="w-25 mb-3 btn btn-primary">
               <label
                  className="custom-file-upload"
                >
                 <input  
                 onChange={(event) => {
                  setImageErr('')
                  handleFileChange(event);
                  setFieldValue("Files", event.currentTarget.files[0]);
                }}
                type="file"
                name="Files"
                id="Files"
                accept="image/*"
                className={`form-control ${
                  errors.Files && touched.Files && "is-invalid"
                }`}
              />
                  Upload Image
                </label>

            </div>
               <div className=" ">
                 <img className="w-25" src={imageUrl} alt="" />
                </div>
            <ErrorMessage
                name="Files"
                component="label"
                className="invalid-feedback"
              />

              </div>
              {imageErrMsg &&(
                <div className="alert alert-danger">{imageErrMsg}</div>

)}
            <div className="mb-3">
              <label htmlFor="code" className="form-label">
                Code:
              </label>
              <Field
                type="text"
                name="code"
                id="code"
                className={`form-control ${
                  errors.code && touched.code && "is-invalid"
                }`}
              />
              <ErrorMessage
                name="code"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="code" className="form-label">
                Long:
              </label>
              <Field
                type="number"
                name="Long"
                id="Long"
                className={`form-control ${
                  errors.Long && touched.Long && "is-invalid"
                }`}
              />
              <ErrorMessage
                name="Long"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="Size" className="form-label">
                Size:
              </label>
              <Field
                type="number"
                name="Size"
                id="Size"
                className={`form-control ${
                  errors.Size && touched.Size && "is-invalid"
                }`}
              />
              <ErrorMessage
                name="Size"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="Description" className="form-label">
                Description:
              </label>
              <Field
                as="textarea"
                name="Description"
                id="Description"
                className={`form-control ${
                  errors.Description && touched.Description && "is-invalid"
                }`}
              />
              <ErrorMessage
                name="Description"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="Description_ar" className="form-label">
                Arabic Description:
              </label>
              <Field
                as="textarea"
                name="Description_ar"
                id="Description"
                className={`form-control ${
                  errors.Description_ar && touched.Description_ar && "is-invalid"
                }`}
              />
              <ErrorMessage
                name="Description_ar"
                component="div"
                className="invalid-feedback"
              />
            </div>


            <div className="mb-3">
              <label htmlFor="CategoryId" className="form-label">
                Category:
              </label>
              <Field
                as="select"
                name="CategoryId"
                id="CategoryId"
                className={`form-control ${
                  errors.CategoryId && touched.CategoryId && "is-invalid"
                }`}
              >
                <option value="" disabled>
                  Select category
                </option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>{category.name}</option>
                ))}
              </Field>
              <ErrorMessage
                name="CategoryId"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <button type="submit" className="btn btn-primary"  disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </Form>
        )}
      </Formik>
      )}
    </section>
  );
};
