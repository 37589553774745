import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import slider1 from '../../assets/images/home-slider/imgs/Home Slide 1.jpg'
import slider2 from '../../assets/images/home-slider/imgs/Home Slide 2.jpg'
import slider3 from '../../assets/images/home-slider/imgs/Home Slide 3.jpg'
import slider4 from '../../assets/images/home-slider/imgs/Home Slide 4.jpg'
import homeGif from  '../../assets/images/our-products/1 (6).gif';

export const SimpleSlider = () => {
  return <>
    <Carousel className=' text-center mb-5 ' autoPlay infiniteLoop interval={2000} showArrows={true} showIndicators={false} showStatus={false}>
      <div>
        <img src={slider1} alt="slider1" />
        <div className='legend d-flex justify-content-center align-items-center'>
          <div>
          </div>
        </div>
      </div>
      <div>
        <img src={slider2} alt="slider2"/>
        <div className='legend d-flex justify-content-center align-items-center'>
        </div>
      </div>
      <div>
        <img src={slider3} alt="slider3" />
        <div className='legend d-flex justify-content-center align-items-center'>
        </div>
      </div>
      <div>
        <img src={slider4} alt="slider4" />
        <div className='legend d-flex justify-content-center align-items-center'>
        </div>
      </div>
    </Carousel>
  <div className='pt-5 text-center'>
<img className='w-50' src={homeGif} alt="homeGif"/>  
  </div>

</>
    
  
};

