import { Link } from "react-router-dom"
import Videos from "../shared/all-videos"
import { Preloader } from "../preloader/preloader";
import { Footer } from "../footer/footer";


export const OurWorksVideos = ()=>{
   const {videos,loading} = Videos()
   console.log('videos',videos);
   if (loading === true) {
     return <Preloader/>
   }
return<>
         <div className="w-100 p-4">
            <h2 className="title text-cap">our videos</h2>
          </div>
<div className="row container m-auto">
{videos.map((video, index) => (
              <div className="col-md-6 card border-0" key={index}>
                <div className="overflow-hidden">
                  <video controls className="w-100">
                    <source src={video.attachmentUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="card-body">
                  <Link className="text-decoration-none text text-dark">
                    <h5 className="card-title">
                     {video.title}
                    </h5>
                  </Link>
                  <p className="card-text">
                  {video.title}
                  </p>
                </div>
              </div>
           
            ))}
</div>
<Footer/>  

</>
}