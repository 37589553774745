import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap-rtl/dist/css/bootstrap-rtl.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "@fortawesome/fontawesome-free/css/all.css";
import { useTranslation } from 'react-i18next';

import './App.css';
import React, { useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { HomeComponent } from './components/home/home';
import { NotFound } from './components/NotFound/not-found';
import { Layout } from './components/layout/layout';
import { ContactUs } from './components/contact-us/contact-us';
import { AboutUs } from './components/about-us/about-us';
import { Products } from './components/products/products';
import { Visualize } from './components/visualize-categories/visualize';
import { Dashboard } from './components/admin/dashboard/dashboard';
import { SignIn } from './components/admin/signIn/signIn';
import { AllProducts } from './components/admin/product/all-products/all-products';
import { CreateProduct } from './components/admin/product/create-product/create-product';
import { CategoryProducts } from './components/admin/product/category-product/category-product';
import { AllCategories } from './components/admin/category/all-categories/all-categories';
import { CreateCategory } from './components/admin/category/create-category/create-category';
import { AllVideos } from './components/admin/our-works/all-videos/all-videos';
import { CreateVideo } from './components/admin/our-works/create-video/create-video';
import { DashboardLogo } from './components/admin/dashboard-logo/dashboard-logo';
import { OurWorksImages} from './components/our-works-images/our-works-images';
import { OurWorksVideos } from './components/our-videos/our-videos';
import { AddWork } from './components/admin/our-works/gallery/create-work';
import { AllWorks } from './components/admin/our-works/gallery/all-works';
import { AddWorkImages } from './components/admin/our-works/gallery/work-images/add-work-images';
import { AllWorkImages } from './components/admin/our-works/gallery/work-images/all-works-images';
import { ProductById } from './components/product/product';
import { CategoryById } from './components/admin/category/category-by-id/category-by-id';
import { AuthGuard } from './components/AuthGuard/AuthGuard';
import { DashboardProduct } from './components/admin/product/product-by-id/product-by-id';
import { UpdateCategory } from './components/admin/category/update-category/update-category';
import { VisualizeProducts } from './components/visualize-products/visualize-products';
import { PrivacyAndPolicy, privacyAndPolicy } from './components/privacy&policy/privacy&policy';
let routers = createBrowserRouter([
  {
    path: '',
    element: <Layout />,
    children: [
      { index: true, element: <HomeComponent /> },
      { path: 'contact-us', element: <ContactUs /> },
      { path: 'about-us', element: <AboutUs /> },
      { path: 'products', element: <Products /> },
      { path: 'products/:id', element: <Products /> },
      { path: 'product/:id', element: <ProductById /> },
      { path: 'visualize', element: <Visualize /> },
      { path: 'visualize/:categoryId', element: <VisualizeProducts /> },
      { path: 'our-works-images', element: <OurWorksImages /> },
      { path: 'our-works-images/:id', element: <OurWorksImages /> },
      { path: 'our-works-videos', element: <OurWorksVideos /> },
      { path: 'login', element: <SignIn /> },
      { path: 'privacy&policy', element: <PrivacyAndPolicy /> },
      { path: '*', element: <NotFound /> },
    ],
  },
  { path: 'dashboard', element:<AuthGuard><Dashboard /></AuthGuard>,children:[
     { index: true, element: < DashboardLogo/> },
    { path: 'create-product', element: <CreateProduct /> },
    { path: 'create-product/:id', element: <CreateProduct /> },
    { path: 'all-products/:id', element: <AllProducts /> },
    { path: 'all-products', element: <AllProducts /> },
    { path: 'product-details/:id', element: <DashboardProduct /> },
    { path: 'category/:id', element: <CategoryById /> },
    { path: 'create-category', element: <CreateCategory /> },
    { path: 'create-category/:categoryId', element: <CreateCategory /> },
    { path: 'create-category/:id', element: <CreateCategory /> },
    { path: 'all-categories', element: < AllCategories/> },
    { path: 'all-videos', element: < AllVideos/> },
    { path: 'create-video', element: < CreateVideo/> },
    { path: 'add-work', element: < AddWork/> },
    { path: 'add-work/:workId', element: < AddWork/> },
    { path: 'all-works', element: < AllWorks/> },
    { path: 'add-work-images/:workId', element: < AddWorkImages/> },
    { path: 'all-work-images/:workId', element: < AllWorkImages/> },
  ]}
]);

function App() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const language = localStorage.getItem('language') || 'ar';
    i18n.changeLanguage(language); 
  }, [i18n]); 
    return (
    <RouterProvider router={routers}></RouterProvider>
  );
}

export default App;
