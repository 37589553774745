import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Footer } from "../footer/footer";
import SharedProducts from "../shared/products";
import { Preloader } from "../preloader/preloader";
import { useTranslation } from "react-i18next";

export const VisualizeProducts = () => {
  const {t,i18n} = useTranslation()

  const { products } = SharedProducts();
  const { categoryId } = useParams();
  const [displayProducts, setProducts] = useState([]);
  const [categoriesBg, setAllCategoriesBg] = useState([]);
  const [backGroundImg, setBackGroundImg] = useState("");
  const [backGroundId, setBackgroundId] = useState("");
  const [productId, setProductId] = useState("");
  const [productImg, setProductImg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setBackGroundImg(categoriesBg[0]?.attachmentUrl);
    setProductImg(displayProducts[0]?.attachmentUrl);
  }, [categoriesBg, displayProducts]);

  async function fetchData() {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `https://api.violetdecoreg.com/api/Admin/GetVisualizeCategoryByid/${categoryId}`
      );
      setProducts(data.products);
      // console.log(data.products);
      setAllCategoriesBg(data.background);
      console.log(data);
      // console.log("displayProducts", displayProducts);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  }
  useEffect(() => {
    fetchData();
  }, [categoryId, products]);
  if (isLoading) {
    return <Preloader />;
  } else {
    return (
      <>
        <section>
          <div className="d-flex container my-5" dir={i18n.language === 'ar'?"rtl":"ltr"}>
            <div className="col-md-6 me-2">
              <div className="parent position-relative">
                <div className="background">
                  <img className="w-100" src={backGroundImg} alt="" />
                </div>
                <div className="product position-absolute top-0 left-0 text-center ">
                  <img className="w-75 pt-5 mt-3" src={productImg} alt="productImg" />
                </div>
              </div>
            </div>
            <div className="col-md-6 p-3">
              <div className="backGrounds mb-4">
                <div>
                  <div
                    className="bg-light py-3 px-4 d-flex justify-content-between align-items-center"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                    data-bs-target="#collapseExample1"
                  >
                    <div>
                      <h5 className="arabic-font">{t("space")}</h5>
                    </div>
                    <div>
                      <i className="fa-solid fa-angle-down "></i>
                    </div>
                  </div>
                </div>
                <div className="collapse bg-light" id="collapseExample1">
                  <div className="card card-body bg-light border-0 border-top rounded-0 mx-3">
                    <div className="d-flex">
                      {categoriesBg.map((categoryBg) => (
                        <div
                          id={categoryBg.id}
                          onClick={() => setBackgroundId(categoryBg.id)}
                          className="me-3 custom-position-relative col-md-2"
                        >
                          <div className="custom-position-absolute rounded-circle bg-white px-1 ms-1 mt-1">
                            {backGroundId == categoryBg.id && (
                              <i className="fa-solid fa-check text-primary"></i>
                            )}
                          </div>
                          <img
                            onClick={() =>
                              setBackGroundImg(categoryBg.attachmentUrl)
                            }
                            className="rounded cursor-pointer w-100"
                            src={categoryBg.attachmentUrl}
                            alt="BackgroundThumb"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="products mb-4">
                <div>
                  <div
                    className="bg-light py-3 px-4 d-flex justify-content-between align-items-center"
                    data-bs-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <div>
                      <h5 className="arabic-font">{t("products")}</h5>
                    </div>
                    <div>
                      <i className="fa-solid fa-angle-down "></i>
                    </div>
                  </div>
                </div>
                <div className="collapse bg-light" id="collapseExample">
                  <div className="card card-body bg-light border-0 border-top rounded-0 mx-3">
                    <div className="row">
                      {/* mapping on categories */}
                      {displayProducts.map((categoryProduct) => (
                        <div
                          id={categoryProduct.id}
                          onClick={() => setProductId(categoryProduct.id)}
                          key={categoryProduct.productId}
                          className="me-3 custom-position-relative col-md-2"
                        >
                          <div className="custom-position-absolute rounded-circle bg-white px-1 ms-1 mt-1">
                            {productId == categoryProduct.id && (
                              <i className="fa-solid fa-check text-primary"></i>
                            )}
                          </div>
                          <img
                            className="rounded cursor-pointer w-100"
                            onClick={() =>
                              setProductImg(categoryProduct.attachmentUrl)
                            }
                            src={categoryProduct.attachmentUrl}
                            alt="product1"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-light text-center">
                <Link
                  to="/visualize"
                  className="ot-btn btn-dark-color text-cap text-decoration-none mt-3 arabic-font"
                >
                  {t("back to categories")}
                </Link>
              </div>
            </div>
          </div>
          {/* footer */}
          <Footer></Footer>
        </section>
      </>
    );
  }
};
