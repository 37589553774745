import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { Preloader } from "../../../preloader/preloader";

export const AllWorks = () => {
const [loading, setLoading] = useState(true)
const [newWorks, setNewWorks] = useState([])
const [message, setMessage] = useState('')
const [noWorkMessage, setNoWorkMessage] = useState('')
const [isWork, setIsWork] = useState(true)
const [isDeleted,setIsDeleted] = useState(false)

useEffect(()=>{
  fetchWorks()
  },[])

const fetchWorks = async () => {
  try {
      const { data } = await axios.get("https://api.violetdecoreg.com/api/Admin/GetAllWorks");
      setNewWorks(data);
      setLoading(false)
      if (data.length === 0) {
          setIsWork(false);
          setNoWorkMessage('No works to view!');
      }
  } catch (error) {
      console.error("Error fetching works:", error);
  }
};

  async function deleteWork(id){
    setNewWorks(newWorks.filter(work => work.id !== id));
    const { data } =await axios.delete(`https://api.violetdecoreg.com/api/Admin/DeleteWork/${id}`)
    console.log(data);
    if (data=='Work removed') {
     // fetchWorks()
     if (newWorks.length == 1) {
      setIsWork(false);
      setNoWorkMessage('No works to view!');
  }
      setIsDeleted(true)
      setMessage('work deleted successfully')
}
  }
 if (loading) {
  return <Preloader/>
 }
  return (
    <>
      <section className="p-5 ">
        <div className="text-center pb-3">
          <h2>All Works</h2>
        </div>
        {isDeleted && (
         <div className="alert alert-primary" role="alert">
      {message}
       </div>
        )}
      {isWork == false && (
         <div className="alert alert-primary" role="alert">
      {noWorkMessage}
       </div>
        )}
        <table className='border table table-striped table-hover border'>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">work cover</th>
              <th scope="col">name</th>
              <th scope="col">photos number</th>
              <th scope="col">view</th>
              <th scope="col">update</th>
              <th scope="col">add work images</th>
              <th scope="col">delete</th>
            </tr>
          </thead>
          <tbody>
            {newWorks.map((item,index)=>(
            <tr key={item.id}>
            <th scope="row">{index+1}</th>
            <td><img className="w-25" src={item.attachmenturl} alt="" /></td>
            <td>{item.name}</td>
            <td>{item.worksNumber}</td>
            <td>
            <Link to={`/dashboard/all-work-images/${item.id}`}>
              <button className="btn btn-outline-primary me-2">view</button>
             </Link> 
            </td>
            <td>
              <Link to={`/dashboard/add-work/${item.id}`} className="btn btn-outline-primary me-2">update</Link>
            </td>           
             <td>
              <Link to={`/dashboard/add-work-images/${item.id}`}>
              <button className="btn btn-outline-primary me-2">add images</button>
             </Link>
            </td>
            <td>
              <button onClick={()=>deleteWork(item.id)} className="btn btn-outline-danger">delete</button>
            </td>
          </tr>
            ))}
          </tbody>
        </table>
      </section>
    </>
  );
};
