import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const CreateVideo = () => {
const navigate = useNavigate()
  const initialValues = {
    video: undefined,
    title: '',
    description: '',
  };
  const validationSchema = Yup.object().shape({
    video: Yup.mixed().required('Video is required'),
    title: Yup.string().required('Title is required'),
    description: Yup.string()
  });

  async function handleSubmit(values , { setSubmitting }) {
    try {
      console.log("values", values);
      const formData = new FormData()
      formData.append('video', values.video)
      formData.append('title', values.title)
      formData.append('description', values.description)
      const { data } = await axios.post('https://api.violetdecoreg.com/api/Admin/AddVideo', formData, {
        headers: {
          "Content-Type": 'multipart/form-data'
        }
      });
      console.log('res', data);
      navigate('/dashboard/all-videos')
    } catch (error) {
      console.log(error)
    }finally{
      setSubmitting(false)
    }
  }

  return (
    <section className='p-5'>
      <h2 className='text-center'>Create video</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, setFieldValue , isSubmitting}) => (
          <Form>
            <div className="mb-3">
              <label htmlFor="video" className="form-label">
                Video:
              </label>
              <input
                type="file"
                name="video"
                id="video"
                accept="video/*"
                onChange={(event) => setFieldValue("video", event.currentTarget.files[0])}
                className={`form-control ${errors.video && touched.video && 'is-invalid'}`}
              />
              <ErrorMessage name="video" component="div" className="invalid-feedback" />
            </div>

            <div className="mb-3">
              <label htmlFor="title" className="form-label">
                Title:
              </label>
              <Field
                type="text"
                name="title"
                id="title"
                className={`form-control ${errors.title && touched.title && 'is-invalid'}`}
              />
              <ErrorMessage name="title" component="div" className="invalid-feedback" />
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Description:
              </label>
              <Field
                type="text"
                name="description"
                id="description"
                className={`form-control ${errors.description && touched.description && 'is-invalid'}`}
              />
              <ErrorMessage name="description" component="div" className="invalid-feedback" />
            </div>
            <button type="submit" className="btn btn-primary"  disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </Form>
        )}
      </Formik>
    </section>
  );
};
