import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from './local/en.json';
import translationAR from './local/ar.json';
import Backend from 'i18next-http-backend';
import languageDetector from 'i18next-browser-languagedetector'
const resources = {
  en: {
    translation: translationEN
  },
  ar: {
    translation: translationAR
  }
};

i18n
.use(Backend)
.use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",

    interpolation: {
      escapeValue: false 
    },
    react:{
      useSuspense: false
    }
  });

  export default i18n;