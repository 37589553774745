import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Works from '../../../../shared/all-works'
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';

export const AddWorkImages = () => {
  const navigate = useNavigate()
  const {workId} = useParams()
  const { works } = Works();

  const initialValues = {
    file: undefined,
    WorkId: workId,
  };
  async function createProduct(values, { setSubmitting }) {
    try {
      console.log(values);
      const formData = new FormData();
      formData.append("file", values.file);
      formData.append("WorkId", workId);
      const { data } = await axios.post(
        "https://api.violetdecoreg.com/api/Admin/AddWorkPhoto",
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      console.log( 'AddPhotos' ,data);
      navigate('/dashboard/all-works')
    } catch (error) {
      console.log(error);
    }finally{
      setSubmitting(false)
    }
  }
  const validationSchema = Yup.object().shape({
    file: Yup.mixed().required("File is required"),
    WorkId: Yup.string().required("Work is required"),
  });

  return (
    <section className="p-5">
      <h2 className="text-center text-cap">add work images</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={createProduct}
      >
        {({ errors, touched, setFieldValue, isSubmitting }) => (
          <Form>
            <div className="mb-3">
              <label htmlFor="File" className="form-label">
                File:
              </label>
              <input
                type="file"
                name="file"
                id="file"
                accept="image/*"
                onChange={(event) =>
                  setFieldValue("file", event.currentTarget.files[0])
                }
                className={`form-control ${
                  errors.file && touched.file && "is-invalid"
                }`}
              />
              <ErrorMessage
                name="file"
                component="div"
                className="invalid-feedback"
              />
            </div>

            {/* <div className="mb-3">
              <label htmlFor="workId" className="form-label">
              Works:
              </label>
              <Field
                as="select"
                name="WorkId"
                id="WorkId"
                className={`form-control ${
                  errors.WorkId && touched.WorkId && "is-invalid"
                }`}
              >
                <option value="" disabled>
                  Select work
                </option>
                {works.map((work) => (
                  <option key={work.id} value={work.id}>{work.name}</option>
                ))}
              </Field>
              <ErrorMessage
                name="WorkId"
                component="div"
                className="invalid-feedback"
              />
            </div> */}

            <button type="submit" className="btn btn-primary"  disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </Form>
        )}
      </Formik>
    </section>
  );
};
