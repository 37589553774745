import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import NewProducts from '../shared/new-products';
import Categories from '../shared/categories';
import { useTranslation } from 'react-i18next';

export const LatestProducts = ({ setProducts }) => {
  const { t,i18n} = useTranslation()
  const navigate = useNavigate()
  const [id, setId] =useState(0)
  const {categories,loading} = Categories()
  const {newProducts} = NewProducts(id)
  
  useEffect(() => { 
    if (newProducts) {
      setProducts(true) 
    //  sendLoading() 
    console.log(newProducts);
    } 
  }, [newProducts]);

const handleClick = (categoryId) => {
  navigate(`/products/${categoryId}`);
};

  return <>
      <section className="padding bg-grey padding-bottom-0 pb-5 my-b">
        <div className="title-block">
          <h2 className="title text-cap arabic-font">{t("latest products")}</h2>
          <div className="divider divider-1">
            <svg className="svg-triangle-icon-container">
              <polygon
                className="svg-triangle-icon"
                points="6 11,12 0,0 0"
              ></polygon>
            </svg>
          </div>
        </div>
        <div className="lastest-project-warp clearfix"  dir={i18n.language === 'ar'?"rtl":"ltr"}>
          <div className=" projectFilter project-terms">
            {categories.map((category)=>(
            <Link onClick={() => setId(category.id)}  key={category.id}
            className={`text-cap ${id === category.id ? 'current' : ''}`}
            >
            <h4 className="h5 arabic-font ">{i18n.language === 'en' ?category.name:category.name_ar}</h4>
          </Link>
            ))}

            <Link onClick={() => setId(0)} 
            className={`text-cap ${id === 0 ? 'current' : ''}`}
            >
              <h4 className='text-cap arabic-font'>{t("all products")}</h4>
            </Link>
          </div>
          <div className="d-flex flex-wrap clearfix projectContainer">
            {newProducts.map((product)=>(
           <div  onClick={() => handleClick(product.categoryId)} key={product.id} className=" element-item Residential">
           <img src={product.attachmenturl} className="img-responsive w-100" alt="Image" />
           <div className=''>
           <div className="project-info">
             <Link className='' >
               <h4 className="title-project text-cap text-cap">{product.code}</h4>
             </Link>
             
             <Link  className="cateProject">
               {i18n.language === 'en'? product.categoryName : product.categoryName_ar}
             </Link>
           </div>
           </div>
         </div>
            ))}
          </div>
        </div>
        <div className="overlay-arc d-flex justify-content-center align-items-center">
          <div className="layer-1">
            <Link to='/products' className="ot-btn btn-dark-color text-cap text-decoration-none arabic-font">
            {t("view all products")}

            </Link>
          </div>
        </div>
      </section>
    </>
};
