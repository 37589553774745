import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

export const CreateCategory = () => {
const [loading, setLoading] = useState(true)
const [imageUrl, setImageUrl] = useState('');
const [imageErrMsg,setImageErr] = useState('')
  const { categoryId } = useParams()
  const navigate = useNavigate()
  const [h2, setH2] = useState('create category')
  const [initialValues, setInitialValues] = useState({
    file: '',
    Name: '',
    Name_ar: '',
  })

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }else{
      setImageUrl('');

    }

  }

useEffect(()=>{
  if (categoryId) {
    setH2('update category')
  }
},[categoryId])


useEffect(()=>{
async function fetchProductDetails(){
  try{
    if(categoryId){
      const {data} = await axios.get(`https://api.violetdecoreg.com/api/Admin/GetCategorybyId/${categoryId}`)
      console.log(data);
      setImageUrl(data.attachmentUrl)
        setInitialValues({
          Name: data.categoryName,
          Name_ar: data.category_arName
      });
      }
    }catch (error) {
    console.error('Error fetching category:', error);
  }finally{
    setLoading(false)
  }
}
fetchProductDetails()
},[categoryId])
  // Define validation schema
  const validationSchema = Yup.object().shape({
    file: Yup.mixed(),
    Name: Yup.string().required('Name is required'),
    Name_ar: Yup.string().required('Arabic Name is required'),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const formData = new FormData();
      formData.append('file', values.file);
      formData.append('Name', values.Name);
      formData.append('Name_ar', values.Name_ar);
      if (categoryId) {
        const { data } = await axios.put(`https://api.violetdecoreg.com/api/Admin/UpdateCategory/${categoryId}`,
          formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        console.log( 'updateCategory' ,data);
        navigate(`/dashboard/category/${categoryId}`)

       }else if(values.file != undefined){
      const response = await axios.post('https://api.violetdecoreg.com/api/Admin/AddCategory', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Category created:', response.data);
      navigate('/dashboard/all-categories')
    }else{
      console.log(values.file);
        setImageErr('image is required!')
    }
    } catch (error) {
      console.error('Error creating category:', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <section className='p-5'>
      <h2 className='text-center text-cap'>{h2}</h2>
      {!loading && (
      <Formik
        initialValues = {initialValues}
        validationSchema = {validationSchema}
        onSubmit = {handleSubmit}
      >
        {({ errors, touched, setFieldValue, isSubmitting }) => (
          <Form>
          
         <div className="d-flex align-items-center  text-center mt-3">
         <div className="w-25 mb-3 btn btn-primary">
            <label
               className="custom-file-upload"
             >
              <input  
              onChange={(event) => {
               setImageErr('')
               handleFileChange(event);
               setFieldValue("file", event.currentTarget.files[0]);
             }}
             type="file"
             name="file"
             id="file"
             accept="image/*"
             className={`form-control ${
               errors.file && touched.file && "is-invalid"
             }`}
           />
               Upload Image
             </label>

         </div>
            <div className=" ">
              <img className="w-25" src={imageUrl} alt="" />
             
             </div>
         <ErrorMessage
             name="Files"
             component="label"
             className="invalid-feedback"
           />

           </div>
           {imageErrMsg &&(
             <div className="alert alert-danger">{imageErrMsg}</div>

)}

            <div className="mb-3">
              <label htmlFor="Name" className="form-label">
                Name:
              </label>
              <Field
                type="text"
                name="Name"
                id="Name"
                className={`form-control ${errors.Name && touched.Name && 'is-invalid'}`}
                // value={initialValues.Name}
             />
              <ErrorMessage name="Name" component="div" className="invalid-feedback" />
            </div>
            <div className="mb-3">
              <label htmlFor="Name_ar" className="form-label">
              Arabic Name:
              </label>
              <Field
                type="text"
                name="Name_ar"
                id="Name_ar"
                className={`form-control ${errors.Name && touched.Name && 'is-invalid'}`}
                // value={initialValues.Name}
             />
              <ErrorMessage name="Name_ar" component="div" className="invalid-feedback" />
            </div>
            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </Form>
        )}
      </Formik>
      )}
    </section>
  );
};
