import axios from "axios";
import { useEffect, useState } from "react";

function Videos(){
   const [videos, setVideos]= useState([])
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);
   
   useEffect(() => {
     async function fetchVideos() {
       setLoading(true);
       try {
         const { data } = await axios.get('https://api.violetdecoreg.com/api/Admin/GetAllVideo');
         setVideos(data);
         setLoading(false);
       } catch (error) {
        console.log(error);
         setError(error);
         setLoading(false);
       }
     }
   
     fetchVideos();
   }, []) ;


   return { videos, loading, error };
}
 
export default Videos