import { useState } from "react"
import violetLogo from '../../assets/images/Violet-logo-thim-05.png'
export const Preloader = ()=>{
    const [showPreLoader, setShowPreLoader] = useState(true)
    const closePreLoader = ()=>{
        setShowPreLoader(false)
    }
   return<>
       {/*  Start preloader */}
       {showPreLoader&&(
    <div className="loader-wrap">
        <div className="preloader">
            <div onClick={closePreLoader} className="preloader-close">x</div>
            <div id="handle-preloader" className="handle-preloader">
                <div className="animation-preloader">
                    <div className="spinner">
                        <div className="violetLogo">
                            <img src={violetLogo} alt="" />
                        </div>
                    </div>
                     {/* <div className="text-center pb-5">
                            <img src={violetLogo} alt="" />
                        </div> */}
                    <div className="txt-loading">
                        <span data-text-preloader="v" className="letters-loading">
                            v
                        </span>
                        <span data-text-preloader="i" className="letters-loading">
                            i
                        </span>
                        <span data-text-preloader="o" className="letters-loading">
                            o
                        </span>
                        <span data-text-preloader="l" className="letters-loading">
                            l
                        </span>
                        <span data-text-preloader="e" className="letters-loading">
                            e
                        </span>
                        <span data-text-preloader="t" className="letters-loading">
                            t
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
       )}

     {/* End preloader  */}
   
   </>
}