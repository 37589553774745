import axios from "axios"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"

export const CategoryById = ()=>{

   const { id } = useParams()
   const [category, setCategory] = useState({})

   async function getCategory (){
      const {data} =await axios.get(`https://api.violetdecoreg.com/api/Admin/GetCategorybyId/${id}`)
      setCategory(data)
      console.log(data);
   }

   useEffect(()=>{
    getCategory()
   },[])

   return <>
   <div className="container mt-5">
      <div className="col-md-6 m-auto text-center">
      <img className="w-100" src={category.attachmentUrl} alt="violet category" />
      <div className="pt-3">
      <p>{category.categoryName}</p>
      <p>{category.categoryName_ar}</p>
      </div>
      <Link to={`/dashboard/create-category/${category.categoryId}`} className="btn btn-outline-primary me-2">update</Link>
      </div>

   </div>
   </>
}