import axios from "axios";
import { useEffect, useState } from "react";

function SharedProducts(){
   const [products, setProducts]= useState([])
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);
   
   useEffect(() => {
     async function fetchProducts() {
       setLoading(true);
       try {
         const { data } = await axios.get('https://api.violetdecoreg.com/api/Admin/GetAllProducts');
         setProducts(data);
         setLoading(false);
       } catch (error) {
         setError(error);
      console.log(error);
         setLoading(false);
       }
     }
   
     fetchProducts();
   }, []) ;


   return { products, loading, error };
}
 
export default SharedProducts