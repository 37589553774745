import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Preloader } from '../../../preloader/preloader';

export const AddWork = () => {
  const { workId } = useParams();
  const [imageUrl, setImageUrl] = useState('');
  const [imageErrMsg, setImageErr] = useState('');
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    file: undefined,
    Name: '',
    Name_ar: '',
  });

  useEffect(() => {
    async function fetchWorkDetails() {
      if (workId) {
        console.log(workId);
        const { data } = await axios.get(`https://api.violetdecoreg.com/api/Admin/GetWorkbyId/${workId}`);
        console.log('data', data);
        setImageUrl(data.attachmentUrl);
        setInitialValues({
          file: data.attachmentUrl,
          Name: data.name,
          Name_ar: data.Name_ar,
        });
      }
    }
    fetchWorkDetails();
  }, [workId]);

  async function addWorkPhoto(values, { setSubmitting }) {
    try {
      const formData = new FormData();
      formData.append('file', values.file);
      formData.append('Name', values.Name);
      formData.append('Name_ar', values.Name_ar);

      for (const [key, value] of formData.entries()) {
        console.log('Key:', key);
        console.log('Value:', value);
      }

      if (workId) {
        const { data } = await axios.put(`https://api.violetdecoreg.com/api/Admin/UpdateWork/${workId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('update', data);
        navigate(`/dashboard/all-work-images/${workId}`);
      } else {
        const { data } = await axios.post('https://api.violetdecoreg.com/api/Admin/AddWork', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('post', data);
        navigate('/dashboard/all-works');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  }

  const validationSchema = Yup.object().shape({
    file: Yup.mixed().required('File is required'),
    Name: Yup.string().required('Name is required'),
    Name_ar: Yup.string().required('Arabic Name is required'),
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result);
        console.log('image', imageUrl);
      };
      reader.readAsDataURL(file);
    } else {
      setImageUrl('');
    }
  };

  return (
    <section className='p-5'>
      <h2 className='text-center text-cap'>Add Work</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={addWorkPhoto}
        enableReinitialize
      >
        {({ errors, touched, setFieldValue, isSubmitting }) => (
          <Form>
            <div className="d-flex align-items-center text-center mt-3">
              <div className="w-25 mb-3 btn btn-primary">
                <label className="custom-file-upload">
                  <input
                    onChange={(event) => {
                      setImageErr('');
                      handleFileChange(event);
                      setFieldValue("file", event.currentTarget.files[0]);
                    }}
                    type="file"
                    name="file"
                    id="file"
                    accept="image/*"
                    className={`form-control ${errors.file && touched.file && "is-invalid"}`}
                  />
                  Upload Image
                </label>
              </div>
              <div className=" ">
                <img className="w-25" src={imageUrl} alt="" />
              </div>
              <ErrorMessage
                name="file"
                component="label"
                className="invalid-feedback"
              />
            </div>
            {imageErrMsg && (
              <div className="alert alert-danger">{imageErrMsg}</div>
            )}
            <div className="mb-3">
              <label htmlFor="Name" className="form-label">
                English Name:
              </label>
              <Field
                type="text"
                name="Name"
                id="Name"
                className={`form-control ${errors.Name && touched.Name && 'is-invalid'}`}
              />
              <ErrorMessage name="Name" component="div" className="invalid-feedback" />
            </div>

            <div className="mb-3">
              <label htmlFor="Name_ar" className="form-label">
                Arabic Name:
              </label>
              <Field
                type="text"
                name="Name_ar"
                id="Name_ar"
                className={`form-control ${errors.Name_ar && touched.Name_ar && 'is-invalid'}`}
              />
              <ErrorMessage name="Name_ar" component="div" className="invalid-feedback" />
            </div>
            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </Form>
        )}
      </Formik>
    </section>
  );
};
