import axios from "axios"
import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

export const DashboardProduct = ()=>{
   const navigate = useNavigate();
   const { id } = useParams()
   const [product, setProduct] = useState({})

   async function getProduct (){
      const {data} =await axios.get(`https://api.violetdecoreg.com/api/Admin/GetProductbyId/${id}`)
      setProduct(data)
      console.log(data);
   }
  
   useEffect(()=>{
      getProduct()
   },[])
   function getProductById(id) {
      navigate(`/dashboard/create-product/${id}`);
    }
   return <>
   <div className="container ">
      <div className="row m-auto text-center">
         <div className="col-md-6">
      <img className="w-100" src={product.attachment} alt="violet product" />
         </div>
      <div className="col-md-6 pt-5 mt-5">
      <h6 className="text-muted">product code <span className="text-dark">{product.code}</span></h6>
      <p>{product.details}</p>
      <p>{product.details_ar}</p>
      <p>size : {product.size}</p>
      <p>long : {product.long}</p>
      <div className="">
            <button onClick={ ()=>getProductById(product.id) } className="mb-0 ot-btn btn-dark-color text-cap text-decoration-none me-3">update</button>
            <Link to={'/dashboard/all-products'} className="btn my-btn btn text-cap p-2 rounded-0">view all products</Link>
         </div>
      </div>
      </div>
   </div>
   </>
}